<template>
  <el-select
    v-model="customerModel"
    @change="customerChanged"
    autocomplete="new-password"
    :placeholder="$t('COMMON.CUSTOMER')"
    :filterable="filterable"
    :multiple="multiple"
    :disabled="disabled"
    remote
    :remote-method="getCustomers"
    :loading="loading"
  >
    <el-option v-if="showAll" :value="null" :label="$t('COMMON.ALL_CUSTOMERS')">
    </el-option>
    <el-option v-if="allowNone" :value="null" :label="$t('COMMON.NONE')">
    </el-option>
    <el-option
      v-for="customer in customers"
      :key="customer.id"
      :value="customer.id"
      :label="`${customer.customer_name} - ${customer.email}`"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";

export default {
  name: "customer-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    allowNone: {
      type: Boolean,
      default: false,
      description: "Show the option none",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    multiple: {
      type: Boolean,
      default: false,
      description: "Can filter",
    },
    customer: {
      type: String,
      default: null,
      description: "Customer id",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
  },

  data() {
    return {
      customerModel: this.customer,
      customers: {},
    };
  },

  setup() {},

  created() {
    this.getCustomers(null, this.customer);
  },

  methods: {
    async getCustomers(query = null, id = null) {
      try {
        this.loading = true;
        let params = {
          sort: "firstname",
          filter: {
            ...(query ? { search: query } : {}),
            ...(id ? { id: id } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.filterOrganization,
            },
          };
        }

        await this.$store.dispatch("customers/list", params);
        const customersArr = await this.$store.getters["customers/list"];
        this.customers = {};
        customersArr.forEach((customer) => {
          this.customers[customer.id] = customer;
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    customerChanged(customerId) {
      const customer = Object.values(this.customers).find(
        (item) => item.id === customerId
      );
      this.$emit("customerChanged", customerId, customer);
    },
  },

  watch: {
    customer(customer) {
      if (customer) {
        this.customerModel = customer;
        if (customer !== this.customerModel) {
          this.getCustomers(null, customer);
        }
      } else {
        this.customerModel = null;
      }
    },
    filterOrganization(filterOrganization) {
      this.getCustomers();
    },
  },
};
</script>
