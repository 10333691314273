<template>
  <div>
    <div class="row mb-5">
      <span class="display-4 col-12">
        {{ `${customer.customer_name}` }} - {{ $t("COMMON.CONTACTS") }}
      </span>

      <div class="col-12 mt-4">
        <base-button
          type="primary"
          icon
          size="sm"
          @click="addCustomerContact()"
          v-if="$currentUserCan($permissions.PERM_CREATE_CONTACTS)"
        >
          <span class="btn-inner--icon">
            <i class="fas fa-plus"></i>
          </span>
          <span class="btn-inner--text">{{ $t("COMMON.ADD_CONTACT") }}</span>
        </base-button>
      </div>
    </div>

    <customer-view-contacts-add-contact-modal
      :customer="customer"
      :showModal="showContactAddModal"
      @onCloseModal="onCloseModal"
    />

    <contact-list-table
      :filterOrganization="customer.organization.id"
      :filterContactableType="`App\\Models\\Customer`"
      :filterContactableId="customer.id"
      :key="rerenderKey"
    />
  </div>
</template>

<script>
import ContactListTable from "../../ContactManagement/partials/ContactListTable.vue";
import CustomerViewContactsAddContactModal from "./CustomerViewContactsAddContactModal.vue";

export default {
  name: "customer-view-contacts",

  components: { ContactListTable, CustomerViewContactsAddContactModal },

  props: ["customer"],

  data() {
    return {
      showContactAddModal: false,
      rerenderKey: 0,
    };
  },

  computed: {},

  created() {},

  methods: {
    addCustomerContact() {
      this.showContactAddModal = true;
    },

    onCloseModal() {
      this.showContactAddModal = false;
      this.$emit("customerUpdated", true);
      this.rerenderKey++;
    },
  },

  mounted() {},

  watch: {
    customer(customer) {},
  },
};
</script>
