import { CUSTOMER_TYPE_INDIVIDUAL } from "@/constants/customers";

export default {
  type: "customers",
  customer_type: CUSTOMER_TYPE_INDIVIDUAL,
  firstname: null,
  lastname: null,
  email: null,
  phone: null,
  country: "Canada",
  state: "Quebec",
  city: null,
  zipcode: null,
  address: null,
  billing_entity_type: CUSTOMER_TYPE_INDIVIDUAL,
  relationshipNames: ["organization", "tags", "allowedLocations"],
  organization: {
    type: "organizations",
    id: null,
  },
  tags: [],
  allowedLocations: [],

  billing_entity_type: CUSTOMER_TYPE_INDIVIDUAL,
  billing_company_name: null,
  billing_firstname: null,
  billing_lastname: null,
  billing_country: "Canada",
  billing_state: "Quebec",
  billing_city: null,
  billing_zipcode: null,
  billing_address: null,
  billing_email: null,
};
