export default {
  type: "files",
  name: null,
  size: null,
  url: null,
  path: null,
  excerpt: '',
  relationshipNames: ["organization", "fileable", "tags", "allowedLocations"],
  organization: {
    type: "organizations",
    id: null,
  },
  fileable: {
    type: null,
    id: null,
  },
  tags: [],
  allowedLocations: [],
};
