var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{ref:"profile_form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[(
      _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
      !_vm.hideOrganization
    )?_c('base-input',{attrs:{"label":((_vm.$t('COMMON.ORGANIZATION')) + " (*)"),"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"organization":_vm.contact.organization.id,"filterable":true,"showAll":false,"disabled":!!_vm.contact.id},on:{"organizationChanged":function (organizationId) {
          _vm.contact.organization.id = organizationId;
          _vm.contact.contactable.id = null;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS))?_c('base-input',{attrs:{"label":("" + (_vm.$t('COMMON.LOCATION'))),"placeholder":_vm.$t('COMMON.LOCATION')}},[_c('locations-selector',{attrs:{"locations":_vm.contact.allowedLocations,"filterable":true,"showAll":false,"multiple":true,"organization":_vm.contact.organization.id},on:{"locationsChanged":function (locations) {
          _vm.contact.allowedLocations = locations;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.location}}),(_vm.contact.organization.id && !_vm.hideContactable)?_c('base-input',{attrs:{"label":((_vm.$t('CONTACTS.CONTACT_TYPE')) + " (*)"),"placeholder":_vm.$t('CONTACTS.CONTACT_TYPE')}},[_c('el-select',{attrs:{"name":("" + (_vm.$t('CONTACTS.CONTACT_TYPE'))),"placeholder":_vm.$t('CONTACTS.CONTACT_TYPE'),"disabled":!!_vm.contact.id},on:{"onchange":function () {
          _vm.onFormChanged();
        }},model:{value:(_vm.contact.contactable.type),callback:function ($$v) {_vm.$set(_vm.contact.contactable, "type", $$v)},expression:"contact.contactable.type"}},[_c('el-option',{attrs:{"value":"customers","label":_vm.$t("COMMON.CUSTOMER")}}),_c('el-option',{attrs:{"value":"suppliers","label":_vm.$t("COMMON.SUPPLIER")}})],1)],1):_vm._e(),(_vm.contact.contactable.type)?_c('div',[(_vm.contact.contactable.type === 'suppliers' && !_vm.hideContactable)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_SUPPLIERS))?_c('base-input',{attrs:{"label":((_vm.$t('COMMON.SUPPLIER')) + " (*)"),"placeholder":_vm.$t('COMMON.SUPPLIER')}},[_c('supplier-selector',{attrs:{"supplier":_vm.contact.contactable.id,"filterable":true,"showAll":false,"filterOrganization":_vm.contact.organization.id},on:{"supplierChanged":function (supplierId) {
                _vm.contact.contactable.id = supplierId;
                _vm.onFormChanged();
              }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.contactable}})],1)]):_vm._e(),(_vm.contact.contactable.type === 'customers' && !_vm.hideContactable)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_CUSTOMERS))?_c('base-input',{attrs:{"label":((_vm.$t('COMMON.CUSTOMER')) + " (*)"),"placeholder":_vm.$t('COMMON.CUSTOMER')}},[_c('customer-selector',{attrs:{"customer":_vm.contact.contactable.id,"filterable":true,"showAll":false,"filterOrganization":_vm.contact.organization.id},on:{"customerChanged":function (customerId) {
                _vm.contact.contactable.id = customerId;
                _vm.onFormChanged();
              }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.contactable}})],1)]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":("" + (_vm.$t('COMMON.COMPANY_NAME')))},on:{"change":function () {
              _vm.onFormChanged();
            }},model:{value:(_vm.contact.company_name),callback:function ($$v) {_vm.$set(_vm.contact, "company_name", $$v)},expression:"contact.company_name"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.address}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2"},[_c('base-input',{attrs:{"label":((_vm.$t('COMMON.TITLE')) + " (*)"),"placeholder":_vm.$t('COMMON.TITLE')},model:{value:(_vm.contact.title),callback:function ($$v) {_vm.$set(_vm.contact, "title", $$v)},expression:"contact.title"}},[_c('el-select',{attrs:{"name":("" + (_vm.$t('COMMON.TITLE'))),"placeholder":_vm.$t('COMMON.TITLE')},on:{"onchange":function () {
                _vm.onFormChanged();
              }},model:{value:(_vm.contact.title),callback:function ($$v) {_vm.$set(_vm.contact, "title", $$v)},expression:"contact.title"}},_vm._l((_vm.sourcesOption),function(label,val){return _c('el-option',{key:val,attrs:{"value":val,"label":label}})}),1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.title}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.title}})],1),_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":((_vm.$t('COMMON.FIRSTNAME')) + " (*)")},on:{"change":function () {
              _vm.onFormChanged();
            }},model:{value:(_vm.contact.firstname),callback:function ($$v) {_vm.$set(_vm.contact, "firstname", $$v)},expression:"contact.firstname"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.firstname}})],1),_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":((_vm.$t('COMMON.LASTNAME')) + " (*)")},on:{"change":function () {
              _vm.onFormChanged();
            }},model:{value:(_vm.contact.lastname),callback:function ($$v) {_vm.$set(_vm.contact, "lastname", $$v)},expression:"contact.lastname"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.lastname}})],1)]),_c('base-input',{attrs:{"label":("" + (_vm.$t('COMMON.EMAIL'))),"type":'email'},on:{"change":function () {
          _vm.onFormChanged();
        }},model:{value:(_vm.contact.email),callback:function ($$v) {_vm.$set(_vm.contact, "email", $$v)},expression:"contact.email"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.email}}),_c('base-input',{attrs:{"label":("" + (_vm.$t('COMMON.PHONE')))}},[_c('phone-number-input',{attrs:{"phoneNumber":_vm.contact.phone,"required":false},on:{"phoneNumberChanged":function (phone) {
            _vm.contact.phone = phone;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.phone}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":("" + (_vm.$t('COMMON.COUNTRY'))),"placeholder":_vm.$t('COMMON.COUNTRY')}},[_c('country-selector',{attrs:{"country":_vm.contact.country,"filterable":true,"showAll":false},on:{"countryChanged":function (country) {
                _vm.contact.country = country;
                _vm.onFormChanged();
              }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.country}})],1),_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":("" + (_vm.$t('COMMON.STATE'))),"placeholder":_vm.$t('COMMON.STATE')}},[_c('state-selector',{attrs:{"country":_vm.contact.country,"state":_vm.contact.state,"filterable":true,"showAll":false},on:{"stateChanged":function (state) {
                _vm.contact.state = state;
                _vm.onFormChanged();
              }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.state}})],1),_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":("" + (_vm.$t('COMMON.CITY')))},on:{"change":function () {
              _vm.onFormChanged();
            }},model:{value:(_vm.contact.city),callback:function ($$v) {_vm.$set(_vm.contact, "city", $$v)},expression:"contact.city"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.city}})],1),_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":("" + (_vm.$t('COMMON.ZIPCODE')))},on:{"change":function () {
              _vm.onFormChanged();
            }},model:{value:(_vm.contact.zipcode),callback:function ($$v) {_vm.$set(_vm.contact, "zipcode", $$v)},expression:"contact.zipcode"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.zipcode}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":("" + (_vm.$t('COMMON.ADDRESS')))},on:{"change":function () {
              _vm.onFormChanged();
            }},model:{value:(_vm.contact.address),callback:function ($$v) {_vm.$set(_vm.contact, "address", $$v)},expression:"contact.address"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.address}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":("" + (_vm.$t('COMMON.BIRTHDAY'))),"type":'date'},on:{"change":function () {
              _vm.onFormChanged();
            }},model:{value:(_vm.contact.birthday),callback:function ($$v) {_vm.$set(_vm.contact, "birthday", $$v)},expression:"contact.birthday"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.birthday}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":("" + (_vm.$t('COMMON.TAGS'))),"placeholder":("" + (_vm.$t('COMMON.TAGS')))}},[_c('tags-selector',{attrs:{"tags":_vm.contact.tags,"disabled":!_vm.contact.organization.id,"organization":_vm.contact.organization.id},on:{"tagsChanged":function (tags) {
                _vm.contact.tags = tags;
                _vm.onFormChanged();
              }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.tags}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.EXCERPT'),"placeholder":_vm.$t('COMMON.EXCERPT')}},[_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.contact.excerpt),callback:function ($$v) {_vm.$set(_vm.contact, "excerpt", $$v)},expression:"contact.excerpt"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}})],1)]),_c('div',{staticClass:"my-4"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.contact.id ? _vm.$t("CONTACTS.EDIT_CONTACT") : _vm.$t("CONTACTS.ADD_CONTACT"))+" ")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }