<template>
  <div style="display: inline">
    <base-button type="info" icon size="sm" @click="openSubscriptionModal">
      <span class="btn-inner--icon">
        <i class="fas fa-bell"></i>
      </span>
    </base-button>
    <modal :show="showModal" modal-classes="modal-secondary text-left">
      <h3 class="text-center">
        {{ $t("NOTIFICATIONS.SUBSCRIBE_TO_NOTIFICATIONS") }}
      </h3>
      <div class="row m-2">
        <div
          v-for="(event, key) in events"
          :key="key"
          class="col-12 row notification-event"
        >
          <base-checkbox v-model="subscriptionsModel[key]">
            {{ event }}
          </base-checkbox>
        </div>
      </div>
      <template slot="footer">
        <button
          type="submit"
          class="btn btn-primary"
          @click="save"
          :disabled="loading"
        >
          <span v-if="loading" class="btn-inner--icon">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          {{ $t("NOTIFICATIONS.SAVE") }}
        </button>

        <button
          type="button"
          class="btn btn-link ml-auto"
          @click="closeModal"
          :disabled="loading"
        >
          {{ $t("COMMON.CLOSE") }}
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
import swal from "sweetalert2";
import { Option, Select } from "element-ui";

export default {
  name: "notification-subscription",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    objectType: {
      type: String,
      description: "Object type of the notification subscription",
    },
    objectId: {
      type: String,
      description: "Object id of the notification subscription",
    },
    events: {
      type: Object,
      description: "Object format {EVENT_VALUE: EVENT_NAME}",
    },
  },

  data() {
    return {
      subscribedEvents: [],
      subscriptionsModel: {},
      showModal: false,
      loading: false,
    };
  },

  setup() {},

  created() {},

  methods: {
    async openSubscriptionModal() {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
      let params = {
        sort: "event",
        filter: {
          object_type: this.objectType,
          object_id: this.objectId,
        },
        page: {
          number: 1,
          size: 999,
        },
        include: "",
      };
      try {
        await this.$store.dispatch("notificationSubscriptions/list", params);
        this.subscribedEvents =
          this.$store.getters["notificationSubscriptions/list"];
        for (const subscription of this.subscribedEvents) {
          this.subscriptionsModel[subscription.event] = true;
        }
        for (const event of Object.keys(this.events)) {
          if (!Object.keys(this.subscriptionsModel).includes(event)) {
            this.subscriptionsModel[event] = false;
          }
        }
        this.showModal = true;
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
      swal.close();
    },

    closeModal() {
      this.showModal = false;
    },

    async save() {
      this.loading = true;
      try {
        const eventsSubscriptions = [];
        for (const event of Object.keys(this.subscriptionsModel)) {
          if (this.subscriptionsModel[event]) {
            if (!this.subscribedEvents.find((item) => item.event === event)) {
              const subscriptionData = {
                type: "notification-subscriptions",
                event: event,
                object_type: this.objectType,
                object_id: this.objectId,
              };
              await this.$store.dispatch(
                "notificationSubscriptions/add",
                subscriptionData
              );
            }
            eventsSubscriptions.push(event);
          }
        }

        for (const subscription of this.subscribedEvents) {
          if (!eventsSubscriptions.includes(subscription.event)) {
            await this.$store.dispatch(
              "notificationSubscriptions/destroy",
              subscription.id
            );
          }
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }

      this.$notify({
        type: "success",
        message: this.$t("NOTIFICATIONS.SUBSCRIPTIONS_SAVED"),
      });
      this.loading = false;
      this.showModal = false;
    },
  },
};
</script>

<style>
.modal-body .notification-event .custom-control.custom-checkbox label {
  font-size: 1.1rem;
  margin: 20px 0px;
  font-weight: 550;
}
</style>